<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section
      class="bg-half bg-light d-table w-100"
      style="background: url('images/contact-detail.jpg') center center"
    >
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">聯絡我們</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">首頁</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      聯絡我們
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Start Contact -->
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 mt-4 pt-2">
            <div class="card shadow rounded border-0">
              <div class="card-body py-5">
                <h4 class="card-title">嗨，跟嗨嗨數位 Say Hi</h4>
                <div class="custom-form mt-4">
                  <div id="message"></div>
                  <form name="contact-form" id="contact-form">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>
                            姓名
                            <span class="text-danger">*</span>
                            <span class="badge badge-pill badge-outline-danger ml-3">{{ validation.firstError('name') }}</span>
                          </label>
                          <div class="position-relative">
                            <user-icon class="fea icon-sm icons"></user-icon>
                            <input
                              type="text"
                              class="form-control pl-5"
                              placeholder="請輸入姓名"
                              v-model="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>
                            Email
                            <span class="text-danger">*</span>
                            <span class="badge badge-pill badge-outline-danger ml-3">{{ validation.firstError('email') }}</span>
                          </label>
                          <div class="position-relative">
                            <mail-icon class="fea icon-sm icons"></mail-icon>
                            <input
                              type="email"
                              class="form-control pl-5"
                              placeholder="請輸入 Email"
                              v-model="email"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>
                            手機/電話
                            <span class="text-danger">*</span>
                            <span class="badge badge-pill badge-outline-danger ml-3">{{ validation.firstError('phone') }}</span>
                          </label>
                          <div class="position-relative">
                            <phone-icon class="fea icon-sm icons"></phone-icon>
                            <input
                              type="phone"
                              class="form-control pl-5"
                              placeholder="請輸入手機或電話"
                              v-model="phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>
                            公司名稱
                            <span class="badge badge-pill badge-outline-danger ml-3">{{ validation.firstError('company_name') }}</span>
                          </label>
                          <div class="position-relative">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="請輸入公司名稱"
                              v-model="company_name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>
                            預算範圍
                            <span class="badge badge-pill badge-outline-danger ml-3">{{ validation.firstError('budget') }}</span>
                          </label>
                          <div class="position-relative">
                            <select 
                              class="form-control"
                              v-model="budget"
                            >
                              <option>10-20萬</option>
                              <option>20-50萬</option>
                              <option>50-80萬</option>
                              <option>80-120萬</option>
                              <option>120萬以上</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>
                            詢問訊息
                            <span class="text-danger">*</span>
                            <span class="badge badge-pill badge-outline-danger ml-3">{{ validation.firstError('message') }}</span>
                          </label>
                          <div class="position-relative">
                            <message-circle-icon class="fea icon-sm icons"></message-circle-icon>
                            <textarea
                              rows="6"
                              class="form-control pl-5"
                              placeholder="希望我們提供什麼服務？"
                              v-model="message"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end row-->
                    <div class="row">
                      <div class="col-sm-12 text-center">
                        <input
                          type="button"
                          class="submitBnt btn btn-primary btn-block"
                          value="送出"
                          :disabled="buttonDisabled"
                          @click="submit"
                        />
                        <div id="simple-msg"></div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                  <!--end form-->
                </div>
                <!--end custom-form-->
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End contact -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

 <script>
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  BookIcon,
  PhoneIcon,
  MessageCircleIcon,
} from 'vue-feather-icons';

import Navbar from '@/components/navbar';
import Footer from '@/components/footer';
import { Validator } from 'simple-vue-validator'
import axios from 'axios';

/**
 * Page-contact-three component
 */
export default {
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      company_name: null,
      budget: '10-20萬',
      message: null,
      buttonDisabled: false,
    };
  },
  components: {
    Navbar,
    Footer,
    UserIcon,
    PhoneIcon,
    ArrowUpIcon,
    MailIcon,
    BookIcon,
    MessageCircleIcon,
  },
  validators: {
    name: function(value) {
      return Validator.value(value).required('必填欄位').maxLength(30, '不可超過30字元');
    },
    email: function(value) {
      return Validator.value(value).required('必填欄位').email('Email 格式不符');
    },
    phone: function(value) {
      return Validator.value(value).required('必填欄位').maxLength(30, '不可超過30字元');
    },
    company_name: function(value) {
      return Validator.value(value).maxLength(50, '不可超過50字元');
    },
    budget: function(value) {
      return Validator.value(value).required('請選擇');
    },
    message: function(value) {
      return Validator.value(value).required('必填欄位').maxLength(2000, '不可超過2000字元');
    }
  },
  methods: {
    submit() {
      let _this = this;
      _this.buttonDisabled = true;

      this.$validate()
        .then(function(success) {
          console.log('validating');
          if (success) {
            console.log('validate success');
            _this.sendRequest();
          } else {
            _this.buttonDisabled = false;
          }
        });
    },
    sendRequest() {
      let _this = this;

      console.log('send request');
      axios.post('https://api.imin.tw/api/hihi/contact', {
          name: this.name,
          email: this.email,
          phone: this.phone,
          company_name: this.company_name,
          budget: this.budget,
          message: this.message,
        })
        .then(response => {
          _this.$swal({
            icon: 'success',
            title: '成功',
            text: '我們會盡快與您聯繫'
          });
          _this.reset();
        })
        .catch(error => {
          console.log(error.response);
          _this.$swal({
            icon: 'error',
            title: '失敗',
            text: error.response.message,
          })
        })
        .finally(function () {
          _this.buttonDisabled = false;
        });
    },
    reset() {
      this.name = null;
      this.email = null;
      this.phone = null;
      this.company_name = null;
      this.budget = '10-20萬';
      this.message = null;

      this.$validate.reset();
    }
  }
};
</script>